import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicTableOptions,
  ColumnDefinition,
  CreateOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { SpecificationInfo, SpecificationTableType } from '@models/fabrication/specification-info';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfig,
  selectCurrentConfigSpecificationInfos,
} from '@store/selectors/configs.selectors';
import { selectSpecById } from '@store/selectors/specification.selectors';
import {
  LoadSpecificationInfos,
  LoadSpecificationInfosSuccess,
  AddSpecificationInfoSuccess,
  DeleteSpecificationInfosSuccess,
  UpdateSpecificationInfoSuccess,
  AddSpecificationInfo,
  CopySpecificationInfo,
  DeleteSpecificationInfos,
  UpdateSpecificationInfo,
} from '@store/actions/specifications.action';
import { GraphConstants as GC } from '@constants/graph-constants';
import { UpdateConfigSpecificationIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { map, take } from 'rxjs/operators';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { DataElementInternalType } from '@constants/data-element-internal-types';
import { flatten, uniq } from 'lodash';
import { Observable, of } from 'rxjs';
import { ReferenceMap } from '@services/data-services/dynamic-graph-data.service';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { InvalidData, InvalidDataErrorReason } from '@models/fabrication/invalid-data';
import { SchemaService } from '@services/schema.service';
import { CreateDataTypeService } from '@services/create-data-type.service';

@Injectable()
// todo: implement table and form setup
export class DynamicSpecificationInfoSetup extends DynamicDataElementTypeSetup<SpecificationInfo> {
  private defaultCreateType: SpecificationTableType = SpecificationTableType.SingleDimension;

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<SpecificationInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private createTypeService: CreateDataTypeService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.partSpecifications;
  }

  setupOptions() {
    const partSpecSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_PART_SPEC_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_PART_SPEC,
    };

    this.options = {
      dataType: DataElementType.Specification,
      dependentDataTypes: [
        DataElementType.Material,
        DataElementType.MaterialSpecification,
        DataElementType.Connector,
      ],
      createNewInstance: () => {
        const createType = this.createTypeService.getCreateDataType<SpecificationTableType>(
          DataElementType.Specification,
          this.defaultCreateType
        );
        return new SpecificationInfo(createType);
      },
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigSpecificationInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectSpecById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadSpecificationInfos({ config })),
        loadSuccessAction: () => new LoadSpecificationInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteSpecificationInfosSuccess(),
        addDataSuccessAction: () => new AddSpecificationInfoSuccess(),
        updateDataSuccessAction: () => new UpdateSpecificationInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigSpecificationIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: SpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddSpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: SpecificationInfo) =>
          this.store$.dispatch(new UpdateSpecificationInfo({ dataElement: model })),
        copyModelAction: (model: SpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopySpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: SpecificationInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(new DeleteSpecificationInfos({ config, dataElements: models }))
            );
        },
        fixModelAction: (model: SpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.Specification,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_PART_SPEC,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_PART_SPEC_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS,
                  addSuccessAction: new AddSpecificationInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_SPECIFICATIONINFOS,
        schemas: [
          {
            dataType: DataElementType.Specification,
            schema: partSpecSchema,
          },
        ],
        subSchemas: [
          {
            parentSchema: partSpecSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_PART_SPEC_TABLE}`,
          },
          {
            parentSchema: partSpecSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_PART_SPEC_PIPEWORK_TABLE_ENTRY}`,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<SpecificationInfo>> {
    const specificationTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'tableType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.TYPE),
        formatter: (value: SpecificationTableType) => {
          let tableTypeDef = '';

          switch (value) {
            case SpecificationTableType.SingleDimension:
              tableTypeDef = LC.ENUMS.SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION;
              break;
            case SpecificationTableType.SingleDimensionPlusLength:
              tableTypeDef = LC.ENUMS.SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION_PLUS_LENGTH;
              break;
            case SpecificationTableType.LongSideShortSide:
              tableTypeDef = LC.ENUMS.SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE;
              break;
            case SpecificationTableType.LongSideShortSidePlusLength:
              tableTypeDef = LC.ENUMS.SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE_PLUS_LENGTH;
              break;
            default:
              break;
          }

          return this.translate.instant(tableTypeDef);
        },
        visible: true,
      },
      {
        field: 'abbreviation',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ABBREVIATION),
        visible: true,
      },
    ];

    let createOptions: CreateOptions = {
      setType: (type: any) => {
        this.createTypeService.saveCreateDataType({
          dataType: DataElementType.Specification,
          createType: type,
        });
      },
      options: [
        {
          label: this.translate.instant(LC.ENUMS.SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION),
          value: () => SpecificationTableType.SingleDimension,
        },
        {
          label: this.translate.instant(
            LC.ENUMS.SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION_PLUS_LENGTH
          ),
          value: () => SpecificationTableType.SingleDimensionPlusLength,
        },
        {
          label: this.translate.instant(LC.ENUMS.SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE),
          value: () => SpecificationTableType.LongSideShortSide,
        },
        {
          label: this.translate.instant(
            LC.ENUMS.SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE_PLUS_LENGTH
          ),
          value: () => SpecificationTableType.LongSideShortSidePlusLength,
        },
      ],
    };

    // todo: remove this line when support for creation of other specification
    // table types is required
    createOptions = null;

    return of(this.createDynamicTableOptions(specificationTableColumns, createOptions));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<SpecificationInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: SpecificationInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          hiddenFields: ['fabricationReferences'],
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields: ['abbreviation', 'tableType', 'category'],
              expanded: true,
              orderByIncludeFields: true,
              options: {
                disabledFields: ['tableType'],
                selectFields: [
                  {
                    key: 'tableType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      SpecificationTableType,
                      'SpecificationTableType'
                    ),
                  },
                ],
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.TABLES),
              includeFields: ['tables'],
              options: {
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.PartSpecTables,
                    field: 'tables',
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
          ],
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category', 'abbreviation'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.Part, DataElementType.Service],
      clusterIcon: 'properties16',
      createInternalDownstreamGraphNodes: (
        dataElement: SpecificationInfo,
        references: ReferenceMap[],
        isInvalidData: boolean
      ) => {
        if (!dataElement?.tables?.length) {
          return of([
            {
              id: '-2',
              dataType: GC.PLACEHOLDER_GRAPH_NODE_NODATATYPE,
              isExpandable: false,
              hasNoReference: true,
              isInvalidData,
              isInvalidInternalData: isInvalidData,
            },
          ]);
        }

        const materials = references
          .filter((ref) => ref.dataElementType === DataElementType.Material)
          .map((ref) => ref.dataElement);

        const graphNodes = dataElement.tables.map((table, index) => {
          const { shape, domain, appliesTo } = table;
          const materialExternalId = table.material;

          const selectedMaterial = materials.find((m) => m.externalId === materialExternalId);

          return {
            id: table.id,
            internalRelationshipId: table.id ?? index.toString(),
            internalRelationshipDataType: DataElementInternalType.PartSpecficationTable,
            dbid: dataElement.id,
            dataType: DataElementType.Specification,
            isFocusable: false,
            isExpandable: true,
            isReplaceable: false,
            isRemovable: false,
            isEditable: false,
            info: {
              shape,
              domain,
              appliesTo,
              material: selectedMaterial
                ? selectedMaterial.name
                : this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ANY),
            },
            referenceMetaData: null,
            isInvalidData,
            isInvalidInternalData: isInvalidData,
          };
        });

        return of(graphNodes);
      },
      getInternalDownstreamReferenceFilteredElements(
        dataElement: SpecificationInfo,
        internalRelationshipId: string
      ) {
        const selectedTable = isNaN(internalRelationshipId as any)
          ? (dataElement as SpecificationInfo).tables.find(
              (table) => table.id === internalRelationshipId
            )
          : dataElement.tables[parseInt(internalRelationshipId)];

        const connectorsIds = uniq(
          flatten(
            selectedTable.entries.map((entry) => [entry.primaryConnector, entry.secondaryConnector])
          )
        );
        const materialId = selectedTable.material;

        const refsIds = [...connectorsIds, materialId];

        const dataElementFabricationReferencesFiltered = dataElement.fabricationReferences.filter(
          (ref) => refsIds.includes(ref.externalId)
        );

        return dataElementFabricationReferencesFiltered;
      },
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(models: SpecificationInfo[]): void {
    models?.forEach((model) => {
      model.tables?.forEach((table) => {
        if (table.material === '-1') table.material = EnvironmentConstants.FCS_ANY_MATERIAL;

        table.entries?.forEach((entry) => {
          if (entry.primaryConnector === '-1')
            entry.primaryConnector = EnvironmentConstants.FCS_NONE_CONNECTOR;
          else if (entry.primaryConnector === '-2')
            entry.primaryConnector = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;

          if (entry.secondaryConnector === '-1')
            entry.secondaryConnector = EnvironmentConstants.FCS_NONE_CONNECTOR;
          else if (entry.secondaryConnector === '-2')
            entry.secondaryConnector = EnvironmentConstants.FCS_UNASSIGNED_CONNECTOR;

          if (entry['materialSpecification'] === '-1')
            entry['materialSpecification'] = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
        });
      });
    });
  }

  getIconName(): string {
    return 'properties';
  }

  isFixable(model: InvalidData & SpecificationInfo): boolean {
    if (model.reason === InvalidDataErrorReason.NameTooLong) {
      return true;
    }

    return model.tableType === SpecificationTableType.SingleDimension;
  }

  getInvalidDataErrors(model: SpecificationInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(DataElementType.Specification);
    return this.getStandardInvalidDataError(DataElementType.Specification, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: SpecificationInfo*/): boolean {
    return false;
  }
}
