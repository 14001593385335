import { Injectable } from '@angular/core';
import { DynamicDataElementTypeSetup } from '@data-management/dynamic-data-setup/base/dynamic-data';
import {
  DynamicTableOptions,
  ColumnDefinition,
  CreateOptions,
} from '@models/dynamic-table/dynamic-table-options';
import { DynamicFormOperationType } from '@models/dynamic-form/dynamic-form-types';
import { DynamicFormOptions, DynamicFormStyle } from '@models/dynamic-form/dynamic-form-properties';
import { DataElementType } from '@constants/data-element-types';
import { Store } from '@ngrx/store';
import { FDMState } from '@store/reducers/index';
import { Config } from '@models/fabrication/config';
import { EnvironmentConstants } from '@constants/environment-constants';
import { DynamicGraphOptions } from '@models/dynamic-graph/dynamic-graph-options';
import {
  selectCurrentConfig,
  selectCurrentConfigInsulationSpecificationInfos,
} from '@store/selectors/configs.selectors';
import { selectInsulationSpecById } from '@store/selectors/insulation-specification.selectors';
import {
  LoadInsulationSpecificationInfos,
  LoadInsulationSpecificationInfosSuccess,
  DeleteInsulationSpecificationInfosSuccess,
  AddInsulationSpecificationInfoSuccess,
  UpdateInsulationSpecificationInfoSuccess,
  AddInsulationSpecificationInfo,
  UpdateInsulationSpecificationInfo,
  CopyInsulationSpecificationInfo,
  DeleteInsulationSpecificationInfos,
} from '@store/actions/insulation-specs.action';
import { UpdateConfigInsulationSpecificationIds } from '@store/actions/configs.action';
import { TranslateService } from '@ngx-translate/core';
import {
  InsulationSpecificationInfo,
  InsulationSpecificationTableType,
} from '@models/fabrication/insulation-specification-info';
import { map, take } from 'rxjs/operators';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { FormUtils } from '@utils/formly/formly-utils';
import { DynamicFormCustomComponentType } from '@constants/dynamic-form-custom-component-types';
import { selectInternalInvalidData } from '@store/selectors/invalid-data.selectors';
import { FixInvalidData } from '@store/actions/invalid-data.action';
import { FabricationReference } from '@models/forge-content/references';
import { InvalidDataErrorService } from '@services/invalid-data-error.service';
import { InvalidData, InvalidDataErrorReason } from '@models/fabrication/invalid-data';
import helpLinks from '@assets/help/help-links.json';
import { EnvironmentService } from '@services/environment.service';
import { SchemaService } from '@services/schema.service';
import { Observable, of } from 'rxjs';
import { DomainType } from '@models/fabrication/common';
import { CreateDataTypeService } from '@services/create-data-type.service';

@Injectable()
// todo: implement table and form setup
export class DynamicInsulationSpecificationInfoSetup extends DynamicDataElementTypeSetup<InsulationSpecificationInfo> {
  private defaultCreateType = InsulationSpecificationTableType.SingleDimension;

  constructor(
    store$: Store<FDMState>,
    translate: TranslateService,
    invalidDataService: InvalidDataErrorService<InsulationSpecificationInfo>,
    schemaService: SchemaService,
    environmentService: EnvironmentService,
    private createDataTypeService: CreateDataTypeService
  ) {
    super(store$, translate, invalidDataService, schemaService, environmentService);
  }

  get helpLinkId(): string {
    return helpLinks.dataTypes.insulationSpecifications;
  }

  setupOptions() {
    const insulationSpecSchema = {
      namespace: EnvironmentConstants.FSS_SCHEMA_NAMESPACE,
      version: EnvironmentConstants.FSS_SCHEMA_INSULATION_SPEC_VERSION,
      type: EnvironmentConstants.FSS_SCHEMA_INSULATION_SPEC,
    };

    this.options = {
      dataType: DataElementType.InsulationSpecification,
      dependentDataTypes: [DataElementType.Material, DataElementType.MaterialSpecification],
      createNewInstance: () =>
        new InsulationSpecificationInfo(
          this.createDataTypeService.getCreateDataType<InsulationSpecificationTableType>(
            DataElementType.InsulationSpecification,
            this.defaultCreateType
          )
        ),
      sortFields: ['category', 'name'],
      supportsDynamicUpdates: true,
      selectors: {
        selectAll: (includeInvalidData: boolean) =>
          this.store$.select(selectCurrentConfigInsulationSpecificationInfos(includeInvalidData)),
        selectById: (id: string, getInternalInvalidData?: boolean) =>
          getInternalInvalidData
            ? this.store$.select(selectInternalInvalidData(id, this.fixMissingReferences))
            : this.store$.select(selectInsulationSpecById(id)),
      },
      actions: {
        loadAllAction: (config: Config) =>
          this.store$.dispatch(new LoadInsulationSpecificationInfos({ config })),
        loadSuccessAction: () => new LoadInsulationSpecificationInfosSuccess(),
        deleteDataSuccessAction: () => new DeleteInsulationSpecificationInfosSuccess(),
        addDataSuccessAction: () => new AddInsulationSpecificationInfoSuccess(),
        updateDataSuccessAction: () => new UpdateInsulationSpecificationInfoSuccess(),
        updateDataReferencesAction: (
          config: Config,
          dataIds: string[],
          deleteReferences: boolean
        ) =>
          new UpdateConfigInsulationSpecificationIds(
            {
              id: config.externalId,
              changes: dataIds,
            },
            deleteReferences
          ),
        createModelAction: (model: InsulationSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new AddInsulationSpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        editModelAction: (model: InsulationSpecificationInfo) =>
          this.store$.dispatch(new UpdateInsulationSpecificationInfo({ dataElement: model })),
        copyModelAction: (model: InsulationSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new CopyInsulationSpecificationInfo({
                  config,
                  dataElement: model,
                })
              )
            );
        },
        deleteModelsAction: (models: InsulationSpecificationInfo[]) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new DeleteInsulationSpecificationInfos({ config, dataElements: models })
              )
            );
        },
        fixModelAction: (model: InsulationSpecificationInfo) => {
          this.store$
            .select(selectCurrentConfig)
            .pipe(take(1))
            .subscribe((config) =>
              this.store$.dispatch(
                new FixInvalidData({
                  config,
                  dataElement: model,
                  dataElementType: DataElementType.InsulationSpecification,
                  fixSchemaType: EnvironmentConstants.FSS_SCHEMA_INSULATION_SPEC,
                  fixSchemaVersion: EnvironmentConstants.FSS_SCHEMA_INSULATION_SPEC_VERSION,
                  nodeId: EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS,
                  addSuccessAction: new AddInsulationSpecificationInfoSuccess(),
                })
              )
            );
        },
      },
      fcs: {
        dataTypeExternalNodeId: EnvironmentConstants.FCS_NODE_ID_INS_SPECIFICATIONINFOS,
        schemas: [
          {
            dataType: DataElementType.InsulationSpecification,
            schema: insulationSpecSchema,
          },
        ],
        subSchemas: [
          {
            parentSchema: insulationSpecSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_INSULATION_SPECIFICATION_TABLE}`,
          },
          {
            parentSchema: insulationSpecSchema,
            id: `#${EnvironmentConstants.FSS_SUB_SCHEMA_SINGLE_DIMENSION_TABLE_ENTRY}`,
          },
        ],
      },
    };
  }

  getDynamicTableOptions(): Observable<DynamicTableOptions<InsulationSpecificationInfo>> {
    const insSpecificationInfoTableColumns: ColumnDefinition[] = [
      {
        field: 'name',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.NAME),
        link: { field: 'id' },
        visible: true,
      },
      {
        field: 'category',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.CATEGORY),
        formatter: (value: string) =>
          value || this.translate.instant(LC.DATATYPES.DEFINITIONS.GENERIC.NOT_ASSIGNED),
        visible: true,
      },
      {
        field: 'tableType',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.TYPE),
        formatter: (value: InsulationSpecificationTableType) => {
          let tableTypeDef = '';

          switch (value) {
            case InsulationSpecificationTableType.SingleDimension:
              tableTypeDef = LC.ENUMS.INSULATION_SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION;
              break;
            case InsulationSpecificationTableType.LongSideShortSide:
              tableTypeDef = LC.ENUMS.INSULATION_SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE;
              break;
            default:
              break;
          }

          return this.translate.instant(tableTypeDef);
        },
        visible: true,
      },
      {
        field: 'abbreviation',
        header: this.translate.instant(LC.DATATYPES.DEFINITIONS.COMMON.ABBREVIATION),
        visible: true,
      },
    ];

    let createOptions: CreateOptions = {
      setType: (type: InsulationSpecificationTableType) => {
        this.createDataTypeService.saveCreateDataType({
          dataType: DataElementType.InsulationSpecification,
          createType: type,
        });
      },
      options: [
        {
          label: this.translate.instant(
            LC.ENUMS.INSULATION_SPECIFICATION_TABLE_TYPE.SINGLE_DIMENSION
          ),
          value: () => InsulationSpecificationTableType.SingleDimension,
        },
        {
          label: this.translate.instant(
            LC.ENUMS.INSULATION_SPECIFICATION_TABLE_TYPE.LONG_SIDE_SHORT_SIDE
          ),
          value: () => InsulationSpecificationTableType.LongSideShortSide,
        },
      ],
    };

    // todo: remove this line when support for creation of other specification
    // table types is required
    createOptions = null;
    return of(this.createDynamicTableOptions(insSpecificationInfoTableColumns, createOptions));
  }

  getDynamicFormOptions(
    formOperation: DynamicFormOperationType,
    modelId: string
  ): Observable<DynamicFormOptions<InsulationSpecificationInfo>> {
    const uniqueFieldRestrictions = ['name', 'category'];
    const categories = this.getItemListForTypeaheadControl('category');
    const titleField = 'name';

    return this.getFormModel(formOperation, modelId).pipe(
      map((model: InsulationSpecificationInfo) => {
        return {
          model,
          formOperation,
          applyModelAction: this.getFormApplyAction(formOperation),
          isReadOnly: formOperation === 'view',
          uniqueFields: {
            fields: uniqueFieldRestrictions,
            allElements: () => this.options.selectors.selectAll(true),
          },
          hiddenFields: ['fabricationReferences'],
          groups: [
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.BASIC),
              includeFields: ['abbreviation', 'category', 'tableType'],
              expanded: true,
              orderByIncludeFields: true,
              options: {
                dropdownTypeaheadFields: [
                  {
                    key: 'category',
                    options: categories,
                  },
                ],
                disabledFields: ['tableType'],
                selectFields: [
                  {
                    key: 'tableType',
                    options: FormUtils.mapSelectOptionsFromEnum(
                      this.translate,
                      InsulationSpecificationTableType,
                      'InsulationSpecificationTableType'
                    ),
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
            {
              label: this.translate.instant(LC.DYNAMIC_FORM.TABS.TABLES),
              includeFields: ['tables'],
              options: {
                customComponents: [
                  {
                    type: DynamicFormCustomComponentType.InsSpecTables,
                    field: 'tables',
                  },
                ],
                formStyle: DynamicFormStyle.SIMPLE,
              },
            },
          ],
          formStyle: DynamicFormStyle.NONE,
          titleField,
        };
      })
    );
  }

  getDynamicGraphOptions(): DynamicGraphOptions {
    return {
      nodeInfoFields: ['name', 'category', 'abbreviation'],
      isReplaceable: true,
      isRemovable: true,
      isEditable: true,
      upstreamReferenceDataTypes: () => [DataElementType.Part, DataElementType.Service],
      clusterIcon: 'properties16',
    };
  }

  fixMissingReferences(fabricationReferences: FabricationReference[]): FabricationReference[] {
    return fabricationReferences || [];
  }

  dataFixes(models: InsulationSpecificationInfo[]): void {
    models?.forEach((model) => {
      model?.tables?.forEach((table) => {
        table.entries?.forEach((entry) => {
          if (entry.material === '-1')
            entry.material = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL;
          if (entry.materialSpecification === '-1')
            entry.materialSpecification = EnvironmentConstants.FCS_UNASSIGNED_MATERIAL_SPEC;
        });
      });
    });
  }

  getIconName(): string {
    return 'properties';
  }

  isFixable(model: InvalidData & InsulationSpecificationInfo): boolean {
    if (model.reason === InvalidDataErrorReason.NameTooLong) {
      return true;
    }

    return (
      model.tableType === InsulationSpecificationTableType.SingleDimension &&
      model.tables.every((x) => x.domain === DomainType.Pipework)
    );
  }

  getInvalidDataErrors(model: InsulationSpecificationInfo & InvalidData): string {
    const schema = this.schemaService.getSchemaByDataElementType(
      DataElementType.InsulationSpecification
    );
    return this.getStandardInvalidDataError(DataElementType.InsulationSpecification, model, schema);
  }

  requiresBinaryUpgrade(/*dataElement: InsulationSpecificationInfo*/): boolean {
    return false;
  }
}
